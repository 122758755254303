import { ReactNode, useContext, useState } from "react";

import { Footer } from "../Footer";
import { Header } from "../Header";
import { Toasts } from "../Toasts";

import styles from "./styles.module.scss";
import { StoreContext } from "../../../containers/StoreContext";
import { WelcomePromoToast } from "../../home/WelcomePromoToast";

export type LayoutProps = {
  children: ReactNode;
};

export const Layout = ({ children }: LayoutProps) => {
  const store = useContext(StoreContext);
  const [homeToastIsClosed, setHomeToastIsClosed] = useState(false);
  return (
    <>
      {/* user === null => request finished and no user */}
      {store.user === null ? (
        <WelcomePromoToast
          isClosed={homeToastIsClosed}
          setIsClosed={setHomeToastIsClosed}
        />
      ) : null}

      {/* <ChristmasPromoToast
        isClosed={homeToastIsClosed}
        setIsClosed={setHomeToastIsClosed}
      /> */}
      <Toasts />
      <Header />
      <div className={styles.layout}>{children}</div>
      <Footer />
    </>
  );
};
