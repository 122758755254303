import classNames from "classnames";
import { ReactNode } from "react";

import styles from "./styles.module.scss";

type Props = {
  children: ReactNode;
  className?: string;
};

export const Page = ({ children, className }: Props) => {
  return <div className={classNames(styles.page, className)}>{children}</div>;
};
