import { Page, Title } from "../../common-ui";

import styles from "./styles.module.scss";

export const LegalNotice = () => {
  return (
    <Page className={styles.page}>
      <Title>Mentions légales</Title>

      <div>
        Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour
        la confiance en l'économie numérique, il est précisé aux utilisateurs du
        site Cosy Fantasy l'identité des différents intervenants dans le cadre
        de sa réalisation et de son suivi.
      </div>

      <div>
        <b>Edition du site</b>
      </div>

      <div>
        Le présent site, accessible à l’URL www.cosyfantasy.com (le « Site »),
        est édité par :
      </div>
      <div>
        Alice Dupuy, résidant à RAMBOUILLET, 78120, de nationalité Française
        (France), né(e) le 29/03/1996,
      </div>
      <div>SIRET : 877 515 569 00029</div>

      <div>
        <b>Hébergement</b>
      </div>

      <div>
        Le Site est hébergé par la société Heroku, situé 415 Mission Street
        Suite 300 San Francisco, CA 94105, (contact téléphonique ou email :
        +18662781349).
      </div>

      <div>
        <b>Directeur de publication</b>
      </div>

      <div>Le Directeur de la publication du Site est Alice Dupuy.</div>

      <div>
        <b>Nous contacter</b>
      </div>

      <ul>
        <li>Par email : contact@cosyfantasy.com</li>
        <li>Par courrier : RAMBOUILLET, 78120</li>
      </ul>

      <div>
        <b>Données personnelles</b>
      </div>

      <div>
        Le traitement de vos données à caractère personnel est régi par notre
        Charte du respect de la vie privée, disponible depuis la section "Charte
        de Protection des Données Personnelles", conformément au Règlement
        Général sur la Protection des Données 2016/679 du 27 avril 2016
        («RGPD»).
      </div>
    </Page>
  );
};
