import { instagram, pinterest } from "../../legal/utils";
import iconNoFound from "../../../assets/icons/no-found.png";

import styles from "./styles.module.scss";

export const EmptyMsg = () => {
  return (
    <div className={styles.container}>
      <img src={iconNoFound} alt="no found icon" />
      <div>
        {"Actuellement aucun produit ne correspond à votre recherche..."}
      </div>
      <div>
        {"Suivez-moi sur "}
        <a
          href={`https://www.instagram.com/${instagram}/`}
          className={styles.instagram}
        >
          Instagram
        </a>
        {" et "}
        <a
          href={`https://www.pinterest.fr/${pinterest}/`}
          className={styles.pinterest}
        >
          Pinterest
        </a>
        {" pour vous tenir informé de toutes les nouveautés !"}
      </div>
    </div>
  );
};
