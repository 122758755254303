import axios from "axios";
import { isProd } from "../common-ui/utils";
import { createCache } from "./cache";
import {
  Cart,
  CheckoutResponse,
  Order,
  OrderDetail,
  Price,
  Product,
  PromotionCode,
  ShippingRate,
} from "./types";

export const STRIPE_PUBLIC_KEY = isProd
  ? "pk_live_51KoqIuDlgcaIMaIw24GGNEeVRsJ7YVhaRaesuuDDKQInu8sEGUT4Uaa67PBDARR6GGOvavVAWAjoqeWrRfJjXcvI00UGBhLPLS"
  : "pk_test_51KoqIuDlgcaIMaIwedutRRP7GaoMJrDUXZu8e6ADTdDQvthGh4EyNUGcAKM9qX9yaR9VtD0lroVye3vyRhs1FY2700UyxZZz40";

const API_URL = isProd
  ? "https://cosyfantasy.herokuapp.com/"
  : "http://localhost:8080/";

export type FetchApi = (host: string) => {
  cache: ReturnType<typeof createCache>;
  get: (path: string) => Promise<any>;
  post: (path: string, payload: { [key: string]: any }) => Promise<any>;
};

export const axiosApi: FetchApi = (host: string) => {
  const cache = createCache();
  return {
    cache,
    get: (path: string) => {
      const timeout = 60;

      const cacheKey = path;

      const url = `${host}${path}`;

      if (cache.hasValidCache(cacheKey, timeout)) {
        return cache.getCache(cacheKey);
      }

      const promise = axios.get(url);

      cache.setCache(cacheKey, promise);
      return promise;
    },
    post: (path: string, payload: { [key: string]: any }) => {
      const url = `${host}${path}`;
      const promise = axios.post(url, payload);
      return promise;
    },
  };
};

const stripeRessources = (axiosApi: ReturnType<FetchApi>) => ({
  orders: {
    list: (payload: { email: string }) => {
      return axios.post(`${API_URL}orders/`, payload).then((res) => {
        return res.data as Order[];
      });
    },
    get: (params: { orderId: string }) => {
      return axios.get(`${API_URL}orders/${params.orderId}/`).then((res) => {
        return res.data as OrderDetail;
      });
    },
  },
  products: {
    list: () => {
      return axiosApi.get("products/").then((res) => {
        return res.data as Product[];
      });
    },
    get: (params: { productId: string }) => {
      return axiosApi.get(`products/${params.productId}/`).then((res) => {
        return res.data as Product;
      });
    },
    listPrices: (params: { productId: string }) => {
      return axiosApi
        .get(`products/${params.productId}/prices/`)
        .then((res) => {
          return res.data as Price[];
        });
    },
  },
  prices: {
    list: () => {
      return axiosApi.get("prices/").then((res) => {
        return res.data as Price[];
      });
    },
  },
  shipping: {
    list: () => {
      return axiosApi.get("shipping-rates/").then((res) => {
        return res.data as ShippingRate[];
      });
    },
  },
  promotionCodes: {
    get: (payload: { promotion_code: string }) => {
      return axiosApi
        .post("promotion-codes/", payload)
        .then((res) => {
          return res.data as PromotionCode;
        })
        .catch((e) => {
          throw e?.response?.data;
        });
    },
  },
  checkout: (payload: {
    line_items: Cart;
    email?: string | null;
    promotion_code?: string;
    shipping_rate: string;
  }) => {
    return axiosApi
      .post("checkout/", payload)
      .then((res) => {
        return res.data as CheckoutResponse;
      })
      .catch((e) => {
        throw e?.response?.data;
      });
  },
});

export const initApiClient = (host: string) => stripeRessources(axiosApi(host));

export const apiClient = initApiClient(API_URL);
