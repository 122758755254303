import { Page, Title } from "../../common-ui";

import styles from "./styles.module.scss";

export const Refund = () => {
  return (
    <Page className={styles.page}>
      <Title>Politique de remboursement</Title>

      <div>
        Certains Produits (comme le thé) proposés sur le site Cosy Fantasy sont
        périssables. Ils ne sont donc pas soumis au droit de rétractation et ne
        peuvent donc pas être remboursés.
      </div>

      <div>
        En cas de livraison non conforme à la commande ou défectueuse, le Client
        est invité à contacter dans les plus brefs delais, et au plus tard 3
        jours après la livraison de sa commande, Cosy Fantasy via l’adresse mail
        suivante : contact@cosyfantasy.com, en spécifiant son numéro de commande
        afin de pouvoir trouver un arrangement.
      </div>

      <div>
        Le Client peut aussi dans ce cas réexpédier la commande dans son
        emballage d’origine en indiquant le motif de refus sur la facture et
        peut demander soit une nouvelle livraison conforme à la commande, soit,
        annulation de la commande défectueuse (avec remboursement de celle-ci).
      </div>

      <div>Les coûts de renvoi de la commande sont à la charge du Client.</div>
    </Page>
  );
};
