import { useState } from 'react';
import classNames from 'classnames';

import { CountriesDelivery } from '../CountriesDelivery';

import leaf from '../../../assets/icons/leaf.svg';
import lock from '../../../assets/icons/lock.svg';
import world from '../../../assets/icons/world.svg';

import styles from './styles.module.scss';

type PaymentInfosProps = {
	isATea?: boolean;
};

export const PaymentInfos = ({ isATea }: PaymentInfosProps) => {
	const [showCountryList, setShowCountryList] = useState(false);

	return (
		<div className={styles.infos}>
			<div className={styles.info}>
				{isATea && (
					<>
						<img src={leaf} alt="Icone de feuille" />
						<div>
							<div>Thés biologiques</div>
							<div className={styles.subInfo}>
								Labels{' '}
								<a href="https://www.ecocert.com/fr/article/5090889" target="_blank" rel="noreferrer">
									Eurofeuille
								</a>{' '}
								et{' '}
								<a href="https://www.oekolandbau.de/en/" target="_blank" rel="noreferrer">
									Bio Nach
								</a>
							</div>
						</div>
					</>
				)}
			</div>
			<div className={styles.info}>
				<img src={lock} alt="Icone cadenas" />
				<div>
					<div>Paiement sécurisé</div>
					<div className={styles.subInfo}>
						Via{' '}
						<a href="https://stripe.com/" target="_blank" rel="noreferrer">
							Stripe
						</a>
					</div>
				</div>
			</div>
			<div className={styles.info}>
				<img src={world} alt="Icone planète" />
				<div>
					<div>Livraison en France et en Europe</div>
					<div
						onClick={() => setShowCountryList(!showCountryList)}
						className={classNames(styles.subInfo, styles.delivery)}
					>
						Voir la liste des pays
					</div>
				</div>
			</div>
			<div
				className={classNames(styles.countryList, {
					[styles.isOpen]: showCountryList,
				})}
			>
				<CountriesDelivery />
			</div>
		</div>
	);
};
