import { Page, Title } from '../common-ui';
import { instagram, pinterest } from '../legal/utils';

import about_1 from '../../assets/images/about_1.jpg';
import about_2 from '../../assets/images/about_2.jpg';

import styles from './styles.module.scss';

export const About = () => {
	return (
		<Page className={styles.page}>
			<Title>À propos</Title>
			<div className={styles.topContainer}>
				<img src={about_1} alt="workshop illustration" />
				<div>Moi c’est Alice, j’ai 26 ans, et je suis la fondatrice de Cosy Fantasy !</div>
				<div>
					Pourquoi ai-je créé Cosy Fantasy ? Quelles sont mes motivations et mes valeurs ? Quel est mon
					parcours ?
				</div>
				<div>Laissez-moi vous expliquer tout ça à travers cette courte introduction à mon univers 😉</div>
				<div>
					Après l’acquisition d’un diplôme en biologie, et d’un autre en pâtisserie, je me suis mis à la
					recherche d’un projet motivant et stimulant, dans lequel je pourrais m’investir à 100%.
				</div>
				<div>
					Un projet qui me permettrait d’inventer, de m’amuser, de créer, d’imaginer et d’explorer de nouveaux
					horizons.
				</div>
				<div>
					Passionnée par les <b>mondes fantastiques et merveilleux</b>{' '}
					<i>
						(de la beauté de Rivendell, aux féroces dragons Targaryens, en passant par les mystérieux couloirs
						de Poudlard)
					</i>
					, par les <b>arts créatifs</b> <i>(peinture, dessin, scénographie…)</i> et par la{' '}
					<b>culture culinaire</b> <i>(pâtisserie, thés, food design…)</i>, il ne restait plus qu’à trouver
					une idée capable d'allier toutes ces passions, et c’est chose faite :
				</div>
			</div>

			<blockquote className={styles.quote}>
				« J’ai désormais un projet pour les gouverner toutes, un projet pour les amener toutes et dans mon
				atelier les lier ». Ce projet, c’est <b>Cosy Fantasy</b> !
			</blockquote>

			<div className={styles.bottomContainer}>
				<img src={about_2} alt="watercolor illustration" />
				<div>
					En effet, le but de <b>Cosy Fantasy</b> est de regrouper à termes toutes mes{' '}
					<b>créations originales</b>, que ce soit à travers des collections de thés{' '}
					<i>
						(imaginées de bout en bout : des illustrations du packaging aux recettes et associations des
						saveurs)
					</i>
					, des collections de bougies, de la papeterie <i>(carnets, blocs-notes, marque-pages)</i>, ou toute
					autre création qui saurait éveiller mon envie et mon imagination, toujours dans l’univers de la
					Fantasy !
				</div>
				<div>
					Et parce que certaines valeurs me sont chères, j’ai décidé de travailler au maximum avec des
					produits et des partenaires locaux, en privilégiant la qualité des produits, l’éthique, et le
					respect de la planète.
				</div>
				<div>
					Voilà, vous en savez maintenant un peu plus sur moi et sur mes motivations qui m’ont amenées à créer
					et développer ce projet qui me tient à coeur.
				</div>
				<div>
					J’espère que vous prendrez autant de plaisir à me suivre et à découvrir mes créations, que j’en
					prend chaque jour à imaginer, concevoir et élaborer mes produits.
				</div>

				<div>À très vite ! 💗</div>
			</div>

			<div className={styles.postScriptum}>
				PS : Pour mieux me connaître et pour avoir un aperçu de mon travail au quotidien n’hésitez pas à me
				suivre sur les réseaux sociaux{' '}
				<a
					href={`https://www.instagram.com/${instagram}/`}
					className={styles.instagram}
					target="_blank"
					rel="noreferrer"
				>
					Instagram
				</a>{' '}
				et{' '}
				<a
					href={`https://www.pinterest.fr/${pinterest}/`}
					className={styles.pinterest}
					target="_blank"
					rel="noreferrer"
				>
					Pinterest
				</a>{' '}
				!
			</div>
		</Page>
	);
};
