import { createContext, Dispatch } from "react";
import { Auth, User } from "firebase/auth";
import { Cart } from "../modules/api-client/types";

// Context

export type StoreContextValue = Store & { dispatch: any };

export const StoreContext = createContext<StoreContextValue>({
  cart: [],
  user: null,
  auth: null,
  dispatch: () => {},
});

export type Store = {
  cart: Cart;
  auth: Auth | null;
  user: User | null;
};

const initialState: Store = {
  cart: [],
  auth: null,
  user: null,
};

// Reducer

export const reducer = (state: Store = initialState, action: any) => {
  switch (action.type) {
    case "RECEIVE_CART": {
      return { ...state, cart: action.payload };
    }
    case "RECEIVE_AUTH": {
      return { ...state, auth: action.payload };
    }
    case "RECEIVE_USER": {
      return { ...state, user: action.payload };
    }
    default:
      return state;
  }
};

// Actions

export const actions = {
  receiveCart: (payload: any) => ({
    type: "RECEIVE_CART",
    payload,
  }),
  receiveAuth: (payload: any) => ({
    type: "RECEIVE_AUTH",
    payload,
  }),
  receiveUser: (payload: any) => ({
    type: "RECEIVE_USER",
    payload,
  }),
};

export type Action = ReturnType<typeof actions[keyof typeof actions]>;

export const receiveCart = (dispatch: Dispatch<Action>, cart: Cart) => {
  dispatch(actions.receiveCart(cart));
};

export const receiveAuth = (dispatch: Dispatch<Action>, auth: Auth) => {
  dispatch(actions.receiveAuth(auth));
};

export const receiveUser = (dispatch: Dispatch<Action>, user: User | null) => {
  dispatch(actions.receiveUser(user));
};
