import classNames from 'classnames';
import { ReactNode, useState } from 'react';

import styles from './styles.module.scss';

type Props = { title: string; description: ReactNode; opened?: boolean };

export const OpenClose = ({ title, description, opened = false }: Props) => {
	const [isOpen, setIsOpen] = useState(opened);
	return (
		<div className={styles.container}>
			<div onClick={() => setIsOpen(!isOpen)} className={styles.title}>
				<div>{title}</div>
				<div className={classNames(styles.chevron, { [styles.chevronReverse]: isOpen })}></div>
			</div>

			<div
				className={classNames(styles.description, {
					[styles.isOpen]: isOpen,
				})}
			>
				{description}
			</div>
		</div>
	);
};
