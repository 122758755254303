import React from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

type TextInputProps = {
  value: string;
  onChange: (s: string) => void;
  type?: "text" | "number" | "password";
  id?: string;
  placeholder?: string;
  margin?: string;
  maxWidth?: boolean;
  disabled?: boolean;
};

export const TextInput = ({
  value,
  onChange,
  type = "text",
  id,
  placeholder,
  margin,
  maxWidth,
  disabled,
}: TextInputProps) => {
  return (
    <input
      value={value}
      onChange={(evt) => onChange(evt.target.value)}
      type={type}
      id={id}
      className={classnames(styles.textInput, {
        [styles.marginRight]: margin && margin.includes("right"),
        [styles.marginBottom]: margin && margin.includes("bottom"),
        [styles.maxWidth]: maxWidth,
        [styles.disabled]: disabled,
      })}
      placeholder={placeholder || ""}
    />
  );
};
