import { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Outlet, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { signOut as signOutFirebase } from "firebase/auth";

import { apiClient } from "../../api-client/apiClient";
import { Order } from "../../api-client/types";
import { Page } from "../../common-ui";

import { receiveUser, StoreContext } from "../../../containers/StoreContext";
import { WelcomeBanner } from "../WelcomeBanner";

import styles from "./styles.module.scss";

export const Account = () => {
  const store = useContext(StoreContext);
  const navigate = useNavigate();

  const informationsTab = "/account/informations/";
  const ordersTab = "/account/orders/";

  const [loading, setLoading] = useState<string | null>(null);
  const [orders, setOrders] = useState<Order[] | null>(null);

  useEffect(() => {
    if (store.user) {
      apiClient.orders
        .list({ email: store.user.email || "" })
        .then((response) => {
          setOrders(response.filter((p: Order) => p.status === "succeeded"));
        });
    }
  }, [store.user]);

  const signOut = () => {
    setLoading("signOut");
    setTimeout(() => {
      signOutFirebase(store.auth!)
        .then(() => {
          setLoading(null);
          receiveUser(store.dispatch, null);
          navigate("/");
        })
        .catch(() => {
          setLoading(null);
          toast.error("Une erreur est survenue.");
        });
    }, 1000);
  };

  if (store.user === null) navigate("/login/");
  if (!store.user) return null;

  return (
    <Page>
      <div className={styles.account}>
        <div className={styles.menu}>
          <div className={styles.menuHeader}>
            Bonjour {store.user.displayName || store.user.email?.split("@")[0]}{" "}
            &#x1F600;
          </div>
          <div className={styles.menuLinks}>
            <NavLink
              to={informationsTab}
              className={({ isActive }) =>
                isActive ? styles.selected : styles.link
              }
            >
              Mes informations
            </NavLink>
            <NavLink
              to={ordersTab}
              className={({ isActive }) =>
                isActive ? styles.selected : styles.link
              }
            >
              Mes commandes
            </NavLink>
            <div onClick={() => signOut()} className={styles.link}>
              {loading === "signOut" ? "Déconnexion..." : "Se déconnecter"}
            </div>
          </div>
        </div>
        <div className={styles.content}>
          {orders && !orders.length ? <WelcomeBanner /> : null}
          <Outlet />
        </div>
      </div>
    </Page>
  );
};
