import classnames from "classnames";

import styles from "./styles.module.scss";

type Props = { value: number; setValue: (v: number) => void; max: number };

export const QuantitySelection = ({ value, setValue, max }: Props) => {
  return (
    <div className={styles.container}>
      <div
        className={classnames(styles.btn, { [styles.disabled]: value < 2 })}
        onClick={() => setValue(value - 1)}
      >
        -
      </div>

      <div className={styles.value}>{value}</div>

      <div
        className={classnames(styles.btn, {
          [styles.disabled]: value >= max,
        })}
        onClick={() => setValue(value + 1)}
      >
        +
      </div>
    </div>
  );
};
