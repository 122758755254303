import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";

import { StoreContext } from "../../../containers/StoreContext";
import { apiClient } from "../../api-client/apiClient";
import {
  Order,
  Price,
  Product,
  PromotionCode,
  ShippingRate,
} from "../../api-client/types";
import { SelectInput } from "../../common-ui/SelectInput";
import { CartItem } from "../CartItem";

import {
  Button,
  Columns,
  Loader,
  Page,
  PaymentInfos,
  TextInput,
  Title,
} from "../../common-ui";
import { formatPrice } from "../../common-ui/utils";
import { getItem } from "../utils";

import warningIcon from "../../../assets/icons/warning.svg";

import styles from "./styles.module.scss";

export const Cart = () => {
  const store = useContext(StoreContext);

  const [products, setProducts] = useState<Product[]>([]);
  const [prices, setPrices] = useState<Price[]>([]);
  const [selectedShippingRate, setSelectedShippingRate] =
    useState<ShippingRate | null>(null);
  const [shippingRateList, setShippingRateList] = useState<ShippingRate[]>([]);
  const [filteredShippingRateList, setFilteredShippingRateList] = useState<
    ShippingRate[]
  >([]);
  const [promotionCode, setPromotionCode] = useState("");
  const [appliedPromotionCode, setAppliedPromotionCode] =
    useState<PromotionCode | null>(null);

  const [loading, setLoading] = useState(true);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [promotionLoading, setPromotionLoading] = useState(false);

  const [sessionUrl, setSessionUrl] = useState<string | null>(null);

  useEffect(() => {
    const p1 = apiClient.shipping.list().then((r) => {
      setSelectedShippingRate(r[1]);
      setShippingRateList(r);
    });
    const p2 = apiClient.products.list().then((r) => {
      setProducts(r);
    });
    const p3 = apiClient.prices.list().then((r) => {
      setPrices(r);
    });
    Promise.all([p1, p2, p3]).then(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (store.user) {
      apiClient.orders
        .list({ email: store.user.email || "" })
        .then((response) => {
          const orderList = response.filter(
            (p: Order) => p.status === "succeeded"
          );
          if (orderList.length === 0) {
            setPromotionCode("WELCOME10");
          }
        });
    }
  }, [store.user]);

  useEffect(() => {
    let rates;

    const cartContainsLargeItem = store.cart.find(
      (item) =>
        getItem({ priceId: item.price, prices, products }).product?.metadata
          .size !== "small"
    );

    if (cartContainsLargeItem) {
      rates = shippingRateList.filter((sr) => !sr.metadata.is_small);
    } else {
      rates = shippingRateList.filter((sr) => sr.metadata.is_small);
    }

    if (cartContainsLargeItem) {
      setSelectedShippingRate(rates[1]);
    } else {
      setSelectedShippingRate(rates[0]);
    }

    setFilteredShippingRateList(rates);
  }, [products, shippingRateList, prices, store.cart]);

  useEffect(() => {
    setSessionUrl(null);
  }, [store.cart]);

  const getTotal = (includePromotion: boolean) => {
    let price = store.cart.reduce(
      (total, item) =>
        (total += getItem({ priceId: item.price, prices, products }).price
          ? getItem({ priceId: item.price, prices, products }).price!
              .unit_amount * item.quantity
          : 0),
      0
    );

    if (includePromotion) {
      if (appliedPromotionCode?.amount_off) {
        price -= appliedPromotionCode.amount_off;
      }
      if (appliedPromotionCode?.percent_off) {
        price = price - price * appliedPromotionCode.percent_off * 0.01;
      }
    }

    if (selectedShippingRate) {
      price += selectedShippingRate.fixed_amount.amount;
    }

    return formatPrice(price);
  };

  const createSession = ({
    redirect,
    promotionCodeToCheck,
  }: {
    redirect?: boolean;
    promotionCodeToCheck?: PromotionCode;
  }) => {
    if (selectedShippingRate) {
      const promoCode = promotionCodeToCheck || appliedPromotionCode;

      apiClient
        .checkout({
          line_items: store.cart,
          ...(store.user && { email: store.user.email }),
          ...(promoCode && {
            promotion_code: promoCode.code,
          }),
          shipping_rate: selectedShippingRate?.id,
        })
        .then((response) => {
          setSessionUrl(response.session_url);

          setPromotionLoading(false);
          setCheckoutLoading(false);

          if (promotionCodeToCheck) {
            setPromotionCode("");
            setAppliedPromotionCode(promotionCodeToCheck);
          }

          if (redirect) window.location.href = response.session_url;
        })
        .catch((e) => {
          toast.error(e.error);
          setPromotionLoading(false);
          setCheckoutLoading(false);
        });
    } else toast.error("Veuillez sélectionner un mode de livraison.");
  };

  const onSubmit = () => {
    if (!sessionUrl) {
      setCheckoutLoading(true);
      createSession({ redirect: true });
    } else {
      window.location.href = sessionUrl;
    }
  };

  const onPromoSubmit = () => {
    setPromotionLoading(true);

    apiClient.promotionCodes
      .get({ promotion_code: promotionCode.trim() })
      .then((response) => {
        createSession({ promotionCodeToCheck: response });
      })
      .catch((e) => {
        setPromotionLoading(false);
        toast.error(e.error);
      });
  };

  if (loading) return <Loader />;

  return (
    <Page>
      <Title>Panier</Title>

      {store.cart.length ? (
        <Columns>
          <div>
            <div className={styles.productList}>
              {store.cart.map((item) => (
                <CartItem
                  item={item}
                  prices={prices}
                  products={products}
                  editable
                />
              ))}
            </div>

            <Button
              to="/shop/"
              className={styles.continueToShop}
              onClick={() =>
                ReactGA.event({
                  category: "CTA",
                  action: "return_to_shop_cart",
                })
              }
            >
              Continuer mes achats
            </Button>
          </div>

          <div>
            <div className={styles.checkout}>
              <div className={styles.total}>
                <div>Montant à payer :</div>
                {!!appliedPromotionCode && (
                  <div className={styles.oldTotalPrice}>{getTotal(false)}</div>
                )}
                <div className={styles.totalPrice}>{getTotal(true)}</div>
              </div>

              <SelectInput
                onChange={(e) => {
                  const rate = filteredShippingRateList.find(
                    (rate) => rate.id === e.value
                  );
                  if (rate) setSelectedShippingRate(rate);
                }}
                options={filteredShippingRateList.map((s) => ({
                  value: s.id,
                  label: `${formatPrice(s.fixed_amount.amount)} (${
                    s.display_name
                  })`,
                }))}
                className={styles.selectShippingRate}
                value={selectedShippingRate?.id || ""}
              />

              {selectedShippingRate?.metadata.is_mondial_relay ? (
                <div className={styles.warning}>
                  <img src={warningIcon} alt="warning" />
                  En choisissant la livraison Mondial Relay, l'adresse
                  renseignée au moment du paiement sera ignorée. Vous recevrez
                  un email sous 24h pour choisir votre point relais.
                </div>
              ) : null}

              <div className={styles.promoContainer}>
                <TextInput
                  value={promotionCode}
                  onChange={(e) => setPromotionCode(e)}
                  placeholder="Code promo"
                  margin="bottom"
                  maxWidth
                />
                <Button
                  onClick={onPromoSubmit}
                  loading={promotionLoading}
                  disabled={!promotionCode}
                  secondary
                >
                  Appliquer
                </Button>
              </div>

              {!!appliedPromotionCode && (
                <div className={styles.appliedPromotionCode}>
                  Code <b>{appliedPromotionCode.code}</b> appliqué.
                </div>
              )}

              <Button
                onClick={() => {
                  onSubmit();
                  ReactGA.event({
                    category: "CTA",
                    action: "checkout_cart",
                  });
                }}
                loading={checkoutLoading}
              >
                Payer
              </Button>
            </div>
            <PaymentInfos isATea />
          </div>
        </Columns>
      ) : (
        <div className={styles.empty}>
          Votre panier est vide.
          <Button
            to="/shop/"
            className={styles.goToShop}
            onClick={() =>
              ReactGA.event({
                category: "CTA",
                action: "go_to_shop_cart",
              })
            }
          >
            Aller voir la boutique
          </Button>
        </div>
      )}
    </Page>
  );
};
