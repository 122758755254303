import { useContext } from "react";
import classNames from "classnames";

import { receiveCart, StoreContext } from "../../../containers/StoreContext";

import {
  CartItem as CartItemType,
  Price,
  Product,
} from "../../api-client/types";

import { QuantitySelection } from "../../common-ui";
import { formatPrice } from "../../common-ui/utils";
import { getItem, setCookie } from "../utils";

import trash from "../../../assets/icons/trash.svg";
import warning from "../../../assets/icons/exclamation-triangle.svg";

import styles from "./styles.module.scss";
import { formatDate, isPreorder } from "../../shop/utils";

type Props = {
  item: CartItemType;
  prices: Price[];
  products: Product[];
  editable?: boolean;
};

export const CartItem = ({ item, prices, products, editable }: Props) => {
  const store = useContext(StoreContext);

  const changeItem = (item: CartItemType, quantity: number) => {
    const newCart = [...store.cart];
    const currentItem = newCart.find((i) => i.price === item.price);
    if (currentItem) currentItem.quantity = quantity;
    receiveCart(store.dispatch, newCart);
    setCookie("cart", JSON.stringify(newCart));
  };

  const removeItem = (item: CartItemType) => {
    const newCart = [...store.cart];
    const currentItem = newCart.find((i) => i.price === item.price);

    if (currentItem) {
      const index = newCart.indexOf(currentItem);
      if (index > -1) {
        newCart.splice(index, 1);
      }
    }

    receiveCart(store.dispatch, newCart);
    setCookie("cart", JSON.stringify(newCart));
  };

  const itemDetail = getItem({ priceId: item.price, prices, products });

  if (!itemDetail.price || !itemDetail.product)
    return (
      <div className={styles.product}>Produit supprimé de la boutique</div>
    );

  const QUANTITY_LEFT = Math.min(
    Number(itemDetail.price.metadata.quantity_left || 100),
    Number(itemDetail.product.metadata.quantity_left || 100)
  );

  return (
    <div
      key={item.price}
      className={classNames(styles.product, { [styles.editable]: editable })}
    >
      <div className={styles.left}>
        <img
          src={itemDetail.product.images[0]}
          alt="img"
          className={styles.logo}
        />
        <div className={styles.description}>
          <div>{itemDetail.product.name}</div>
          <div className={styles.nickname}>{itemDetail.price.nickname}</div>
          {isPreorder(itemDetail.product) ? (
            <div className={styles.preorderWarning}>
              <img src={warning} alt="Attention" />
              <div>{`Pré-commande : expédié à partir du ${formatDate(
                itemDetail.product.metadata.release_date
              )}`}</div>
            </div>
          ) : null}
        </div>
      </div>

      <div className={styles.right}>
        <div className={styles.price}>
          {formatPrice(itemDetail.price.unit_amount)}
        </div>

        {editable ? (
          <div className={styles.quantity}>
            <QuantitySelection
              value={item.quantity}
              setValue={(q) => changeItem(item, q)}
              max={QUANTITY_LEFT}
            />
            <div onClick={() => removeItem(item)}>
              <img src={trash} alt="Supprimer l'article de mon panier" />
            </div>
          </div>
        ) : (
          <div>x{item.quantity}</div>
        )}
      </div>
    </div>
  );
};
