import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

import iconCart from "../../../assets/icons/cart.svg";
import { StoreContext } from "../../../containers/StoreContext";

import logo from "../../../assets/logos/Logo.png";

import { BurgerMenu } from "../../common-ui";
import { navigation, sendGAEvent } from "./utils";

import styles from "./styles.module.scss";

export const Header = () => {
  const store = useContext(StoreContext);

  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

  return (
    <div className={styles.header}>
      <div className={styles.container}>
        <div className={styles.menu}>
          <div>
            <NavLink
              to="/"
              onClick={() => sendGAEvent("Header", "header_home_click")}
              className={styles.menuItem}
            >
              <img src={logo} alt="logo" className={styles.logo} />
            </NavLink>

            {/* DESKTOP */}
            {navigation(store.user).left.map((tab) => (
              <NavLink
                to={tab.to}
                onClick={() => sendGAEvent("Header", `header_${tab.id}_click`)}
                className={styles.desktopOption}
              >
                {tab.label}
              </NavLink>
            ))}
          </div>

          <div>
            {/* DESKTOP */}
            {navigation(store.user).right.map((tab) => (
              <NavLink
                to={tab.to}
                onClick={() => sendGAEvent("Header", `header_${tab.id}_click`)}
                className={styles.desktopOption}
              >
                {tab.label}
              </NavLink>
            ))}

            <NavLink
              to="/cart/"
              className={styles.cart}
              onClick={() => sendGAEvent("Header", "header_cart_click")}
            >
              <img src={iconCart} alt="cart" />
              {!!store.cart.length && (
                <div className={styles.nb}>
                  {store.cart.reduce((a, b) => a + b.quantity, 0)}
                </div>
              )}
            </NavLink>

            {/* MOBILE */}
            <div className={styles.mobileMenu}>
              <BurgerMenu
                isOpen={mobileMenuIsOpen}
                setIsOpen={setMobileMenuIsOpen}
              />

              <div
                onClick={() => setMobileMenuIsOpen(false)}
                className={classNames(styles.mobileMenuContainer, {
                  [styles.opened]: mobileMenuIsOpen,
                })}
              >
                {navigation(store.user).left.map((tab) => (
                  <NavLink
                    to={tab.to}
                    onClick={() =>
                      sendGAEvent("Header", `header_${tab.id}_click`)
                    }
                  >
                    {tab.label}
                  </NavLink>
                ))}

                {navigation(store.user).right.map((tab) => (
                  <NavLink
                    to={tab.to}
                    onClick={() =>
                      sendGAEvent("Header", `header_${tab.id}_click`)
                    }
                  >
                    {tab.label}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
