import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
} from "firebase/auth";

import { Button, Page, TextInput, Title } from "../../common-ui";

import { receiveUser, StoreContext } from "../../../containers/StoreContext";
import { getSignInError } from "../utils";

import iconGoogle from "../../../assets/icons/google.svg";

import styles from "./styles.module.scss";

const provider = new GoogleAuthProvider();

export const Login = () => {
  const store = useContext(StoreContext);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");

  const [loading, setLoading] = useState<string | null>(null);

  if (!store.auth) return null;

  const resetForm = () => {
    setEmail("");
    setPassword("");
  };

  const signIn = () => {
    setLoading("signIn");
    signInWithEmailAndPassword(store.auth!, email, password)
      .then((userCredential) => {
        setLoading(null);
        const user = userCredential.user;
        receiveUser(store.dispatch, user);
        resetForm();
        navigate("/account/informations/");
        toast.dismiss();
      })
      .catch((e) => {
        setError(getSignInError(e.code));
        setLoading(null);
        toast.error("Une erreur est survenue.");
      });
  };

  const signInWithGoogle = () => {
    setLoading("signInWithGoogle");
    signInWithPopup(store.auth!, provider)
      .then((userCredential) => {
        setLoading(null);
        const user = userCredential.user;
        receiveUser(store.dispatch, user);
        resetForm();
        navigate("/account/informations/");
        toast.dismiss();
      })
      .catch((e) => {
        setError(getSignInError(e.code));
        setLoading(null);
        toast.error("Une erreur est survenue.");
      });
  };

  const resetPassword = () => {
    setLoading("resetPassword");
    sendPasswordResetEmail(store.auth!, email, {
      url: window.location.href,
    })
      .then(() => {
        setLoading(null);
        toast.success(`Un email a été envoyé à ${email}`);
      })
      .catch(() => {
        setLoading(null);
        toast.error("Une erreur est survenue.");
        setError("Veuillez renseigner votre email.");
      });
  };

  if (store.user) navigate("/account/informations/");

  return (
    <Page>
      <Title>Connexion</Title>

      <div className={styles.form}>
        <TextInput
          value={email}
          onChange={(e) => setEmail(e)}
          placeholder="Votre email"
          margin="bottom"
          maxWidth
        />
        <TextInput
          value={password}
          onChange={(e) => setPassword(e)}
          placeholder="Votre mot de passe"
          margin="bottom"
          type="password"
          maxWidth
        />
        <div className={styles.error}>{error}</div>
      </div>

      <div className={styles.buttons}>
        <Button onClick={signIn} loading={loading === "signIn"}>
          Se connecter
        </Button>

        <Button
          onClick={signInWithGoogle}
          loading={loading === "signInWithGoogle"}
          className={styles.googleCta}
        >
          <img src={iconGoogle} alt="iconGoogle" />
          Se connecter avec Google
        </Button>

        <Button
          onClick={resetPassword}
          loading={loading === "resetPassword"}
          link
        >
          Mot de passe oublié ?
        </Button>

        <Button to="/register/" link>
          Créer mon compte
        </Button>
      </div>
    </Page>
  );
};
