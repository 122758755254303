import classNames from 'classnames';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

type TitleProps = {
	children: ReactNode;
	align?: 'center' | 'left';
	subtitle?: boolean;
	collectionName?: boolean;
};

export const Title = ({ children, align = 'center', subtitle, collectionName }: TitleProps) => {
	if (subtitle) return <h2 className={classNames(styles.title, styles.subtitle)}>{children}</h2>;

	if (collectionName) return <h2 className={classNames(styles.title, styles.collectionName)}>{children}</h2>;

	return (
		<h1
			className={classNames(styles.title, {
				[styles.left]: align === 'left',
			})}
		>
			{children}
		</h1>
	);
};
