import { Page, Title } from "../../common-ui";
import { company, mail, site } from "../utils";

import styles from "./styles.module.scss";

export const TermsOfSales = () => {
  return (
    <Page className={styles.page}>
      <Title>Conditions Générales de Ventes</Title>

      <div>
        <div>
          L’entreprise Cosy Fantasy immatriculée à la{" "}
          <b>Chambre du Commerce et de l'Industrie</b> des <b>Yvelines</b>{" "}
          <b>78</b>.
        </div>
        <div>Siège social : Rambouillet 78120.</div>
        <div>Créé par : Alice Dupuy.</div>
        <div>Site : {site}.</div>
        <div>Contact : {mail}</div>
        <div>SIRET : 877 515 569 00029</div>
      </div>

      <div>
        <div>On désignera par la suite :</div>
        <ul>
          <li>“Site” : le site {site} et la totalité de ses pages.</li>
          <li>
            “Produits” : l’ensemble des produits proposés sur le site et
            disponibles à l’achat.
          </li>

          <li>
            “Vendeur” : {company} personne physique proposant ses produits via
            le site.
          </li>
          <li>
            “Client” : l’internaute, particulier ou professionnel effectuant un
            achat de produit sur le site.
          </li>
        </ul>
      </div>

      <div>
        L’internaute visitant {site} est invité à lire attentivement le CGV de
        celui-ci avant d’effectuer une commande.
      </div>

      <div>
        Le Client reconnaît avoir pris connaissance des CGV et les accepte
        intégralement et sans réserve.
      </div>

      <div>
        <b>Application des CGV :</b>
      </div>

      <div>
        Cosy Fantasy se réserve le droit de modifier les présentes CGV à tout
        moment en publiant une nouvelle version de ces dernières sur son site.
        Les CGV applicables au Client sont celles en vigueur le jour de sa
        commande sur le Site. Sauf preuve contraire, les informations
        enregistrées par l’entreprise constituent la preuve de l’ensemble des
        transactions. Le Site a pour objet de proposer au consommateur l’achat
        en ligne de thés, d’articles de papeterie, de bougies, de tote bags, etc
        ? ‘autres noms d’articles’. L’accès au Site internet est libre et
        gratuit à tout utilisateur disposant d’un accès à internet.
      </div>

      <div>
        <b>Prix :</b>
      </div>

      <div>
        Les prix de vente pour chacun des produits présentés sur le site sont en
        Euros et toutes taxes comprises (TTC) hors frais de livraison et
        précisément déterminés sur les pages de descriptif des Produits. Le
        montant total dû par le Client est indiqué sur la page finale de
        confirmation de la commande. L’entreprise (le Vendeur) se réserve à tout
        moment le droit de modifier ses prix sur le Site à tout moment mais les
        prix de vente sont ceux en vigueur au moment de la commande, dans la
        limite des stocks disponibles. Les frais de livraison sont à la charge
        du Client et sont indiqués à ce dernier lors du processus de vente et en
        tout état de cause au moment de la confirmation de la commande.
      </div>

      <div>
        <b>Produits :</b>
      </div>

      <div>
        Les Produits proposés par Cosy Fantasy sur le Site comprennent un
        descriptif précis sous forme de textes et d’images (photographies,
        illustrations) qui comprend aussi le prix du Produit et le cas échéant,
        le mode d’utilisation. L’objectif est de présenter le plus fidèlement au
        Client le Produit afin qu’il puisse faire son choix en toute
        connaissance de cause. Cependant, les informations et les photographies
        illustrant ou décrivant le Produit ne constituent pas un document
        contractuel.
      </div>

      <div>
        <b>Disponibilité des Produits :</b>
      </div>

      <div>
        Lorsque les produits ne sont pas disponibles immédiatement, une
        information claire est donnée sur la page de présentation du Produit
        quant à la date de livraison de ce dernier. Le Vendeur s’engage à
        honorer la commande du Client dans la limite des stocks de Produits
        disponibles uniquement. A défaut, le Vendeur en informe le Client, si la
        commande à été passée, et à défaut d’accord avec le Client sur une
        nouvelle date de livraison, le Vendeur rembourse le Client sur la carte
        bancaire ayant servie à l’achat, le remboursement sera effectué dans un
        délais de 30 jours à compter de la date où le Client est informé de
        l’indisponibilité du produit, le montant recrédité sur sa carte bancaire
        sera uniquement celui équivalent au(x) Produit(s) indisponible(s).
      </div>

      <div>
        <b>Clause de réserve de propriété :</b>
      </div>

      <div>
        Les Produits demeurent la propriété de l’entreprise jusqu’au complet
        paiement du prix.
      </div>

      <div>
        <b>Commandes :</b>
      </div>

      <div>
        La prise de commande se fait sur le site www.cosyfantasy.com. Le Client
        choisit son ou ses Produit(s) qu’il met dans son panier, Produits dont
        il pourra modifier le nombre commandé ou supprimer du panier avant de
        passer commande en acceptant les conditions générales de ventes ici
        nommées. Il renseignera ensuite ses informations personnelles
        nécessaires au bon déroulement de la livraison de sa commande (ou se
        connectera à son espace). Après validation des informations, la commande
        sera considérée comme définitive et exigera paiement de la part du
        Client selon les modalités prévues. La validation de la commande et les
        données relatives enregistrées pour celle-ci constituent le présent
        contrat. Le Client doit certifier l’exactitude ainsi que l'exhaustivité
        des informations fournies afin de garantir le bon déroulement de la
        livraison. Cosy Fantasy ne peut être tenu responsable de l’impossibilité
        de livraison des produits dans le cas où les données du Client sont
        insuffisantes ou erronées. Les données enregistrées par le moyen de
        paiement constituent la preuve de la transaction financière. Un premier
        courrier électronique accusant réception de la commande est alors envoyé
        dans les meilleurs délais par Cosy Fantasy au Client.
      </div>

      <div>
        <b>Modalités de livraison :</b>
      </div>

      <div>
        Les Produits sont livrés à l’adresse de livraison indiquée par le Client
        et dans les délais indiqués. Ces délais ne prennent pas en compte le
        temps de préparation de la commande. Le Vendeur précise qu’au moment où
        le Client prend possession de sa commande, les risques de perte et
        d'endommagement des produits lui sont transférés.Il appartient au Client
        de notifier au transporteur toute réserve quant au bon état du produit
        livré.
      </div>

      <div>
        <b>Droit de rétractation et retour :</b>
      </div>

      <div>
        Certains Produits (comme le thé) proposés sur le site Cosy Fantasy sont
        périssables. Ils ne sont donc pas soumis au droit de rétractation et ne
        peuvent donc pas être remboursés.
      </div>

      <div>
        En cas de livraison non conforme à la commande ou défectueuse, le Client
        est invité à contacter dans les plus brefs delais, et au plus tard 3
        jours après la livraison de sa commande, Cosy Fantasy via l’adresse mail
        suivante : {mail}, en spécifiant son numéro de commande afin de pouvoir
        trouver un arrangement.
      </div>

      <div>
        <b>Paiement :</b>
      </div>

      <div>
        Le paiement est exigé immédiatement après commande, y compris pour les
        précommandes. Il est passé par le Client et s’effectue sur le site par
        carte bancaire via le prestataire de paiement sécurisé Stripe. Lorsque
        le Client à rempli son panier, donné les informations nécessaires à la
        livraison, il lui sera demandé de renseigner son numéro de carte
        bancaire, sa date de validité ainsi que son cryptogramme visuel dans le
        formulaire de paiement afin de finaliser sa commande. Le Client confirme
        qu’il est bien le titulaire légal de la carte à débiter et qu’il est
        légalement en droit d’en faire usage. Le paiement est effectif seulement
        si les centres de paiement interrogés donnent leur accord. Si le
        paiement est refusé, la commande sera immédiatement annulée et le Client
        prévenu. Toute commande vaut acceptation des prix.
      </div>

      <div>
        <b>Vérification de la commande :</b>
      </div>

      <div>
        Cosy Fantasy veille à ce que les produits soient emballés afin
        d'empêcher toute dégradation durant le transport de la commande, tout en
        limitant la quantité d'emballage utilisé dans un souci écologique. Le
        Client se doit de vérifier avant le départ du livreur l’état et la
        conformité du colis. Dans le cas où les produits seraient endommagés ou
        manquants, le Client dispose d’un délai de 3 jours pour faire une
        réclamation par écrit à l’adresse mail suivante : {mail}. Cosy Fantasy
        se réserve le droit d’accepter ou non la réclamation. En cas
        d’acceptation, le Produit endommagé ou manquant sera remplacé et renvoyé
        dans les meilleurs délais. Le risque de perte ou d’endommagement des
        produits est transféré au Client à la livraison lorsqu’il les
        réceptionne et en prend physiquement possession (ou un tiers désigné par
        le destinataire).
      </div>

      <div>
        <b>Garanties :</b>
      </div>

      <div>
        Le Vendeur assume les garanties suivantes : rembourser ou échanger les
        articles ne correspondant pas à la commande dans la limite des stocks
        disponibles. La demande doit s’effectuer en contactant le vendeur par
        mail à l’adresse mail suivante : {mail}.
      </div>

      <div>
        <b>Protection des données personnelles :</b>
      </div>

      <div>
        Les informations personnelles et nominatives du Client recueillies sont
        nécessaires pour le traitement et la livraison de la commande ainsi qu’à
        l’établissement de la facture. Les données ne sont en aucun cas
        transmises à des tiers sauf au prestataire de paiement en ligne sécurisé
        Stripe.
      </div>

      <div>
        <b>Droits de propriété intellectuelle :</b>
      </div>

      <div>
        Les marques, nom de domaine, produits, images, textes, ou toute
        information faisant l’objet de propriété intellectuelle sont réservées
        et sont la propriété du Vendeur. Toute reproduction, utilisation,
        représentation, totale ou partielle sont strictement interdites sauf
        autorisation express et préalable du Vendeur.
      </div>

      <div>
        <b>Droit applicable :</b>
      </div>

      <div>
        Seules les présentes Conditions de Vente en langue française font foi.
        Ainsi, toutes les clauses y figurant, ainsi que les opérations d’achat
        et de vente qui y sont visées, seront soumises au droit français.
      </div>
    </Page>
  );
};
