import styles from "./styles.module.scss";
import classNames from "classnames";

export const BurgerMenu = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
}) => {
  return (
    <div
      className={styles.container}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <button
        className={classNames(styles.burgermenu, { [styles.opened]: isOpen })}
      >
        <span></span>
      </button>
    </div>
  );
};
