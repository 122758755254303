import { NavLink } from "react-router-dom";
import ReactGA from "react-ga4";

import { Price, Product } from "../../api-client/types";
import { formatPrice } from "../../common-ui/utils";
import { isNewProduct, isPreorder } from "../utils";

import styles from "./styles.module.scss";
import classNames from "classnames";
import { Button } from "../../common-ui";

type Props = { product: Product; prices: Price[] };

export const ProductCard = ({ product, prices }: Props) => {
  const getLowerPriceDetail = () => {
    const price = prices.reduce((a, b) => {
      if (a.unit_amount > b.unit_amount) return b;
      return a;
    });
    return price;
  };

  const getLowerPrice = () => {
    const price = getLowerPriceDetail();
    const value = formatPrice(price.unit_amount);
    return value;
  };

  return (
    <NavLink
      to={`/shop/${product.id}/`}
      className={classNames(styles.productCard, styles.productCardHover)}
      key={product.id}
      onClick={() =>
        ReactGA.event({
          category: "Product Card click",
          action: `card_${product.name}_click`,
        })
      }
    >
      {/* IMAGE */}
      {!!product.images.length ? (
        <img src={product.images[0]} alt="img" className={styles.productImg} />
      ) : null}

      <div className={styles.name}>{product.name}</div>
      <div className={styles.description}>{product.description}</div>

      {/* TAGS */}
      {isPreorder(product) ? (
        <div className={styles.tag}>{"Pré-commande"}</div>
      ) : null}
      {isNewProduct(product) ? (
        <div className={styles.tag}>{"Nouveauté"}</div>
      ) : null}

      {/* PRICE */}
      <div className={styles.price}>
        {prices.length > 1 ? "À partir de " : null}

        {getLowerPriceDetail().metadata.standard_price ? (
          <>
            <span className={styles.old}>
              {getLowerPriceDetail().metadata.standard_price}
            </span>
            <span className={styles.new}>{getLowerPrice()}</span>
          </>
        ) : (
          getLowerPrice()
        )}
      </div>

      <Button to={`/shop/${product.id}/`} className={styles.btn}>
        Voir le produit
      </Button>

      {/* TRIANGLE */}
      {isPreorder(product) ? <div className={styles.preorderTriangle} /> : null}

      {isNewProduct(product) ? (
        <div className={styles.newProductTriangle} />
      ) : null}
    </NavLink>
  );
};
