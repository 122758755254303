// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { isProd } from "./modules/common-ui/utils";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  dev: {
    apiKey: "AIzaSyC-9BLhf-Y1XyVJ4XPMWgQtpPHUZGllkDk",
    authDomain: "cosyfantasy-dev.firebaseapp.com",
    projectId: "cosyfantasy-dev",
    storageBucket: "cosyfantasy-dev.appspot.com",
    messagingSenderId: "533368097108",
    appId: "1:533368097108:web:fae49275080f1c427de0c1",
  },
  prod: {
    apiKey: "AIzaSyCg3mVEXIFseftL-H-OwOeOYZ-yJzWbzWw",
    authDomain: "cosyfantasy.firebaseapp.com",
    projectId: "cosyfantasy",
    storageBucket: "cosyfantasy.appspot.com",
    messagingSenderId: "521556319037",
    appId: "1:521556319037:web:37782014b420d2df6a4354",
  },
};

// Initialize Firebase
export const initFirebase = () => {
  initializeApp(isProd ? firebaseConfig.prod : firebaseConfig.dev);
};
