import styles from './styles.module.scss';

import franceFlag from '../../../assets/icons/flags/france.png';
import germanyFlag from '../../../assets/icons/flags/germany.png';
import belgiumFlag from '../../../assets/icons/flags/belgium.png';
import denmarkFlag from '../../../assets/icons/flags/denmark.png';
import spainFlag from '../../../assets/icons/flags/spain.png';
import finlandFlag from '../../../assets/icons/flags/finland.png';
import greeceFlag from '../../../assets/icons/flags/greece.png';
import irelandFlag from '../../../assets/icons/flags/ireland.png';
import italyFlag from '../../../assets/icons/flags/italy.png';
import luxembourgFlag from '../../../assets/icons/flags/luxembourg.png';
import portugalFlag from '../../../assets/icons/flags/portugal.png';
import swedenFlag from '../../../assets/icons/flags/sweden.png';
import switzerlandFlag from '../../../assets/icons/flags/switzerland.png';

export const CountriesDelivery = () => {
	const countries = [
		{ name: 'France', flag: franceFlag },
		{ name: 'Allemagne', flag: germanyFlag },
		{ name: 'Belgique', flag: belgiumFlag },
		{ name: 'Danemark', flag: denmarkFlag },
		{ name: 'Espagne', flag: spainFlag },
		{ name: 'Finlande', flag: finlandFlag },
		{ name: 'Grèce', flag: greeceFlag },
		{ name: 'Irlande', flag: irelandFlag },
		{ name: 'Italie', flag: italyFlag },
		{ name: 'Luxembourg', flag: luxembourgFlag },
		{ name: 'Portugal', flag: portugalFlag },
		{ name: 'Suède', flag: swedenFlag },
		{ name: 'Suisse', flag: switzerlandFlag },
	];

	return (
		<div>
			{countries.map((country) => (
				<div className={styles.line}>
					<img src={country.flag} alt="flag" />
					<div>{country.name}</div>
				</div>
			))}
		</div>
	);
};
