import { Page, Title } from "../../common-ui";
import { company, mail, site } from "../utils";

import styles from "./styles.module.scss";

export const TermsOfUse = () => {
  return (
    <Page className={styles.page}>
      <Title>Conditions Générales d'Utilisation</Title>

      <div>
        <b>Préambule : </b>
      </div>

      <div>
        Les présentes conditions générales d’utilisation sont conclues entre :
        le gérant du site qui sera désigné comme “l'Éditeur” toute personne
        accédant au site et à ses services, appelé “l’Utilisateur”.
      </div>

      <div>
        <b>Article 1 - Principes</b>
      </div>

      <div>
        Les présentes conditions générales d’utilisation ont pour objet
        l’encadrement juridique de l’utilisation du site {site}.
      </div>

      <div>
        <div>Entreprise {company}</div>
        <div>Située à Rambouillet (78)</div>
        <div>Adresse URL : {site}</div>
        <div>Adresse mail : {mail}</div>
      </div>

      <div>
        Les conditions générales d’utilisation doivent être acceptées par tout
        Utilisateur, et son accès au site vaut acceptation de ces conditions.
      </div>

      <div>
        <b>Article 2 - Évolution et durée des CGU</b>
      </div>

      <div>
        <div>
          Les présentes conditions générales d’utilisation sont conclues pour
          une durée indéterminée. Le contrat produit ses effets à l’Utilisateur
          à compter du début de l’utilisation du site.
        </div>
        <div>
          Le site {site} se réserve le droit de modifier les clauses de ses
          conditions générales d’utilisation à tout moment et sans
          justification.
        </div>
      </div>

      <div>
        <b>Article 3 - Accès au site</b>
      </div>

      <div>
        Tout Utilisateur ayant accès à internet peut accéder gratuitement et
        depuis n’importe où au site {site}. Les frais supportés par
        l’Utilisateur pour y accéder (connexion internet, matériel informatique,
        etc.) ne sont pas à la charge de l'Éditeur.
      </div>

      <div>
        Le site et ses différents services peuvent être interrompus ou suspendus
        par l’Editeur, notamment à l’occasion d’une maintenance, sans obligation
        de préavis ou de justification.
      </div>

      <div>
        <b>Article 4 - Responsabilités</b>
      </div>

      <div>
        La responsabilité de l’Editeur ne peut être engagée en cas de
        défaillance, panne, difficulté ou interruption de fonctionnement,
        empêchant l'accès au site ou à une de ses fonctionnalités.
      </div>

      <div>
        Le matériel de connexion au site utilisé est sous l’entière
        responsabilité de l’Utilisateur qui doit prendre toutes les mesures
        appropriées pour protéger le matériel et les données notamment
        d’attaques virales par Internet. L’Utilisateur est par ailleurs le seul
        responsable des sites et données qu’il consulte.
      </div>

      <div>
        L’Editeur ne pourra être tenu responsable en cas de poursuites
        judiciaires à l’encontre de l’Utilisateur :
        <ul>
          <li>
            du fait de l’usage du site ou de tout service accessible via
            Internet
          </li>
          <li>
            du fait du non-respect par l’Utilisateur des présentes conditions
            générales
          </li>
        </ul>
      </div>

      <div>
        L'Éditeur n’est pas responsable des dommages causés à l’Utilisateur, à
        des tiers et/ou à l’équipement de l’Utilisateur du fait de sa connexion
        ou de son utilisation du site et l’Utilisateur renonce à toute action
        contre l'Éditeur de ce fait.
      </div>

      <div>
        Si l'Éditeur venait à faire l’objet d’une procédure amiable ou
        judiciaire à raison de l’utilisation du site par l’Utilisateur, il
        pourra retourner contre lui pour obtenir indemnisation de tous les
        préjudices, sommes, condamnations et frais qui pourraient découler de
        cette procédure.
      </div>

      <div>
        <b>Article 5 - Propriété intellectuelle</b>
      </div>

      <div>
        Tous les documents techniques, produits, photographies, textes, logos,
        dessins, vidéos, etc., sont soumis à des droits d’auteur et sont
        protégés par le Code de la propriété intellectuelle. Lorsqu’ils sont
        remis à nos clients, ils demeurent la propriété exclusive de Cosy
        Fantasy, seul titulaire des droits de propriété intellectuelle sur ces
        documents, qui doivent lui être rendus à sa demande.
      </div>
      <div>
        Nos clients s’engagent à ne faire aucun usage de ces documents,
        susceptible de porter atteinte aux droits de propriété intellectuelle ou
        industrielle du fournisseur et s’engagent à ne les divulguer à aucun
        tiers, en dehors de l’autorisation expresse et préalable données par
        l'Éditeur.
      </div>

      <div>
        <b>Article 6 - Liens hypertextes</b>
      </div>

      <div>
        La mise en place de l’Utilisateur de tous liens hypertextes vers tout ou
        partie du site est autorisée par l'Éditeur sous réserves suivantes :
      </div>

      <ul>
        <li>
          L'Éditeur est libre de refuser cette autorisation sans avoir à
          justifier sa décision.
        </li>
        <li>
          Cette autorisation est temporaire et pourra être retirée à tout moment
          sans obligation de justification de l'Éditeur.
        </li>
        <li>
          Dans tous les cas, tout lien devra être retiré sur simple demande de
          l'Éditeur.
        </li>
        <li>
          Toute information accessible via un lien sur d’autres sites n’est pas
          sous le contrôle de l'Éditeur qui décline toute responsabilité quant à
          leur contenu.
        </li>
      </ul>

      <div>
        <b>Article 7 - Protection des données personnelles </b>
      </div>

      <div>Vos données sont collectées par l’entreprise {company}.</div>

      <div>
        <b>Données collectées</b>
      </div>

      <div>
        <div>
          Les données à caractère personnel qui sont collectées sur ce site sont
          les suivantes :
        </div>
        <ul>
          <li>nom et prénom</li>
          <li>une adresse mail</li>
          <li>une adresse postale</li>
          <li>
            des données financières : dans le cadre du paiement des produits
            proposés sur le site web, des données financières relatives au
            compte bancaire ou à la carte de crédit de l’Utilisateur sont
            renseignées
          </li>
          <li>un numéro de téléphone</li>
        </ul>
        <div>
          Elles sont uniquement utilisées par l'Éditeur pour la gestion des
          relations clients ainsi que pour le traitement des commandes.
        </div>
      </div>

      <div>
        <b>Utilisation des données personnelles</b>
      </div>

      <div>
        Les données personnelles collectées auprès des utilisateurs ont pour
        objectif la mise à disposition des services du site web, leur
        amélioration et le maintien d’un environnement sécurisé.
      </div>

      <div>Plus précisément les utilisations sont les suivantes : </div>
      <ul>
        <li>accès et utilisation du site web par l’Utilisateur</li>
        <li>gestion du fonctionnement et optimisation du site web</li>
        <li>
          organisation des conditions d’utilisation du Service de paiement
        </li>
        <li>
          vérification, identification et authentification des données
          transmises par l’utilisateur
        </li>
        <li>mise en oeuvre d’une assistance utilisateurs</li>
        <li>
          prévention et détection des fraudes, malwares (malicious softwares ou
          logiciels malveillant) et gestion des incidents de sécurité
        </li>
        <li>gestion des éventuels litiges avec les utilisateurs</li>
        <li>
          envoi d’informations commerciales et publicitaires en fonction des
          préférences de l’utilisateur
        </li>
      </ul>

      <div>
        <b>Partage des données personnelles avec des tiers</b>
      </div>

      <div>
        Les données personnelles peuvent être partagées avec des sociétés
        tierces dans les cas suivants : lorsque l’Utilisateur utilise les
        services de paiement, pour la mise en oeuvre de ces services, le site
        web est en relation avec des sociétés bancaires et financières tierces
        avec lesquelles elle a passé contrat, lorsque l’Utilisateur publie, dans
        les zones de commentaires libres du site web, des informations
        accessibles au public, lorsque le site web recourt aux services de
        prestataire de service de paiement. Ce prestataire dispose d’un accès
        limité aux données de l’Utilisateur, dans le cadre de l'exécution de ces
        prestations, et a une obligation contractuelle de les utiliser en
        conformité avec les dispositions de la réglementation applicable en
        matière de protection des données à caractère personnel, si la loi
        l’exige, le site web peut effectuer la transmission de données pour
        donner suite aux réclamations présentées contre le site web et se
        conformer aux procédures administratives et judiciaires.
      </div>

      <div>
        <b>Sécurité et confidentialité</b>
      </div>

      <div>
        Le site web met en œuvre des mesures organisationnelles, techniques,
        logicielles et physiques en matière de sécurité du numérique pour
        protéger les données personnelles contre les altérations, destructions
        et accès non autorisés. Toutefois, il est à signaler qu’internet n’est
        pas un environnement complètement sécurisé et le site web ne peut pas
        garantir la sécurité de la transmission ou du stockage des informations
        sur internet.
      </div>

      <div>
        <b>Mise en oeuvre des droits des utilisateurs</b>
      </div>

      <div>
        En application de la réglementation applicable aux données à caractère
        personnel, les utilisateurs disposent des droits suivants, (qu’ils
        peuvent exercer en faisant leur demande à l’adresse suivante :{mail}, ou
        directement sur le site) :
      </div>
      <ul>
        <li>
          le droit d’accès : ils peuvent exercer leur droit d’accès pour
          connaître les données personnelles les concernant. Dans ce cas, avant
          la mise en œuvre de ce droit, le site web peut demander une preuve de
          l’identité de l’Utilisateur afin d’en vérifier l’exactitude.
        </li>
        <li>
          le droit de rectification : si les données à caractère personnel
          détenues par le site web sont inexactes, ils peuvent demander la mise
          à jour des informations.
        </li>
        <li>
          le droit de suppression des données : les utilisateurs peuvent
          demander la suppression de leurs données à caractère personnel,
          conformément aux lois applicables en matière de protection des
          données.
        </li>
        <li>
          le droit à la limitation du traitement : les utilisateurs peuvent
          demander au site web de limiter le traitement des données personnelles
          conformément aux hypothèses prévues par le RGPD.
        </li>
        <li>
          le droit de s’opposer au traitement des données : les utilisateurs
          peuvent s’opposer à ce que leurs données soient traitées conformément
          aux hypothèses prévues par le RGPD.
        </li>
        <li>
          le droit à la portabilité : ils peuvent réclamer que le site web leur
          remette les données personnelles qui lui sont fournies pour les
          transmettre à un nouveau site web.
        </li>
      </ul>

      <div>
        <b>Evolution de la présente clause</b>
      </div>

      <div>
        Le site web se réserve le droit d’apporter toute modification à la
        présente clause relative à la protection des données à caractère
        personnel à tout moment.
      </div>

      <div>
        Si une modification est apportée à la présente clause de protection des
        données à caractère personnel, le site web s’engage à publier la
        nouvelle version sur son site.
      </div>
      <div>
        Si l’Utilisateur n’est pas d’accord avec les termes de la nouvelle
        rédaction de la clause de protection des données à caractère personnel,
        il a la possibilité de supprimer son compte.
      </div>

      <div>
        <b>Article 8 - Cookies</b>
      </div>

      <div>
        Le site {site} peut collecter automatiquement des informations
        standards. Toutes les informations collectées indirectement ne seront
        utilisées que pour suivre le volume, le type et la configuration du
        trafic utilisant ce site, pour en développer la conception et
        l’agencement et à d’autres fins administratives et de planification et
        plus généralement pour améliorer le service que nous vous offrons.
      </div>

      <div>
        <b>Article 9 - Loi applicable</b>
      </div>

      <div>
        Les présentes conditions générales d’utilisation sont soumises à
        l’application du droit français. Si les parties n’arrivent pas à
        résoudre un litige à l’amiable, le litige sera soumis à la compétence
        des tribunaux français.
      </div>

      <div>
        Ces CGU ont été créées selon et avec l’aide du site Rocket Lawyer.
      </div>
    </Page>
  );
};
