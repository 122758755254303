import Cookies from "js-cookie";
import { Price, Product } from "../api-client/types";

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2)
    return parts
      .pop()!
      .split(";")
      .shift()!
      .replaceAll("%22", '"')
      .replaceAll("%2C", ",");
  return null;
};

export const setCookie = (name: string, value: string) => {
  Cookies.set(name, value, { expires: 1 / 24 });
};

export const getItem = ({
  priceId,
  prices,
  products,
}: {
  priceId: string;
  prices: Price[];
  products: Product[];
}) => {
  const price = prices.find((p) => p.id === priceId);
  const product = products.find((p) => p.id === price?.product);
  return { product, price };
};
