import { useEffect, useReducer } from "react";
import ReactGA from "react-ga4";
import { BrowserRouter } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getApps } from "firebase/app";

import { initFirebase } from "../firebaseConfig";

import { getCookie } from "../modules/cart/utils";
import { GA_MESUREMENT_ID, isProd } from "../modules/common-ui/utils";
import { Loader } from "../modules/common-ui";

import { Layout } from "../modules/layout/Layout";

import { Router } from "./Router";

import {
  StoreContext,
  StoreContextValue,
  reducer,
  receiveCart,
  receiveUser,
  receiveAuth,
} from "./StoreContext";

import "./styles.scss";

export const App = () => {
  const [state, dispatch] = useReducer(reducer, {
    cart: [],
    auth: null,
    user: undefined,
  });

  useEffect(() => {
    initFirebase();
    if (isProd) ReactGA.initialize(GA_MESUREMENT_ID);
    receiveCart(dispatch, JSON.parse(getCookie("cart") || "[]"));

    const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
      receiveAuth(dispatch, auth);

      if (user) {
        receiveUser(dispatch, user);
      } else {
        receiveUser(dispatch, null);
      }
    });
  }, []);

  if (!getApps().length) return <Loader />;

  const contextValue: StoreContextValue = { ...state, dispatch };

  return (
    <StoreContext.Provider value={contextValue}>
      <BrowserRouter>
        <Layout>
          <Router />
        </Layout>
      </BrowserRouter>
    </StoreContext.Provider>
  );
};
