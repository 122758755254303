import { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { updateProfile } from "firebase/auth";

import { Button, TextInput, Title } from "../../common-ui";

import { receiveUser, StoreContext } from "../../../containers/StoreContext";

import styles from "./styles.module.scss";

export const Informations = () => {
  const store = useContext(StoreContext);
  const navigate = useNavigate();

  const [displayName, setDisplayName] = useState(store.user?.displayName || "");

  const [loading, setLoading] = useState<string | null>(null);

  useEffect(() => {
    if (store.user?.displayName) setDisplayName(store.user.displayName);
  }, [store.user]);

  if (!store.auth || !store.auth.currentUser) return null;

  const update = () => {
    setLoading("update");

    updateProfile(store.auth?.currentUser!, { displayName })
      .then(() => {
        setLoading(null);
        receiveUser(store.dispatch, {
          ...store.user!,
          displayName,
        });
        toast.success("Modifications enregistrées !");
      })
      .catch(() => {
        setLoading(null);
        toast.error("Une erreur est survenue.");
      });
  };

  // const sendVerificationEmail = () => {
  //   setLoading("verification");

  //   sendEmailVerification(store.auth?.currentUser!, {
  //     url: window.location.href,
  //   })
  //     .then(() => {
  //       setLoading(null);
  //       toast.success(`Un email a été envoyé à ${store.user?.email}`);
  //     })
  //     .catch(() => {
  //       setLoading(null);
  //       toast.error("Une erreur est survenue.");
  //     });
  // };

  if (!store.user) {
    navigate("/login/");
    return null;
  }

  return (
    <>
      <Title>Mes informations</Title>
      <div className={styles.form}>
        <div className={styles.label}>Email</div>
        <TextInput
          value={store.user.email || ""}
          onChange={() => null}
          placeholder="Email"
          margin="bottom"
          maxWidth
          disabled
        />

        <div className={styles.label}>Nom</div>
        <TextInput
          value={displayName}
          onChange={(e) => setDisplayName(e)}
          placeholder="Nom"
          margin="bottom"
          maxWidth
        />

        <Button
          onClick={update}
          loading={loading === "update"}
          className={styles.button}
        >
          Modifier
        </Button>
      </div>

      {/* <div className={styles.buttons}>
            {store.user.emailVerified ? (
            <Button to="/orders/">Mes commandes</Button>
            ) : null}

          {!store.user.emailVerified ? (
            <>
              <div className={styles.error}>
                Vérifiez votre compte pour avoir accès à vos commandes.
              </div>
              <Button
                onClick={sendVerificationEmail}
                loading={loading === "verification"}
              >
                Envoyer un email de vérification
              </Button>
            </>
          ) : null}
        </div> */}
    </>
  );
};
