export const getSignInError = (e: string) => {
  switch (e) {
    case "auth/email-already-in-use":
      return "Cet email est déjà utilisé.";
    case "auth/internal-error":
      return "Vous n'avez pas rentré de mot de passe.";
    case "auth/invalid-email":
      return "Votre email est invalide.";
    case "auth/wrong-password":
      return "Votre mot de passe est invalide.";
    case "auth/user-not-found":
      return "Aucun compte avec cet email.";
    case "auth/weak-password":
      return "Le mot de passe doit contenir au moins 6 caractères.";
    default:
      return "Une erreur est survenue.";
  }
};
