import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";

import { NavLink } from "react-router-dom";
import ReactGA from "react-ga4";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";

import {
  Button,
  Columns,
  Loader,
  OpenClose,
  Page,
  PaymentInfos,
  Title,
} from "../../common-ui";

import { Price, Product } from "../../api-client/types";
import { apiClient } from "../../api-client/apiClient";

import exclamationTriangle from "../../../assets/icons/exclamation-triangle.svg";
import temperature from "../../../assets/icons/temperature.svg";
import timer from "../../../assets/icons/timer.svg";
import quantity from "../../../assets/icons/quantity.svg";
import { formatDate, isPreorder } from "../utils";

import { ProductDetailPrices } from "./ProductDetailPrices";

import styles from "./styles.module.scss";
import { getProductImages } from "../products";

export const ProductDetail = () => {
  const { id } = useParams();

  const [quantities, setQuantities] = useState<{ [k: string]: number }>({});

  const [prices, setPrices] = useState<Price[]>([]);
  const [selectedPrice, setSelectedPrice] = useState<Price | null>(null);

  const [product, setProduct] = useState(null as Product | null);
  const [loading, setLoading] = useState(true);

  const sliderRef = useRef<Slider>(null);

  useEffect(() => {
    apiClient.products
      .get({ productId: id! })
      .then((product_response) => {
        setProduct(product_response);

        apiClient.products
          .listPrices({ productId: product_response.id })
          .then((prices_response) => {
            const productPrices = prices_response.filter(
              (price) => price.product === id
            );
            setPrices(productPrices);
            if (productPrices.length) setSelectedPrice(productPrices[0]);

            const q: { [k: string]: number } = {};
            prices_response.forEach((price) => (q[price.id] = 1));
            setQuantities(q);

            setLoading(false);
          });
      })
      .catch(() => setLoading(false));
  }, [id]);

  if (loading) return <Loader />;

  if (!product)
    return (
      <Page>
        <Title>Ce produit n'existe pas.</Title>
        <Button to="/shop/">Retour à la boutique</Button>
      </Page>
    );

  function CustomArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        id="arrow-size"
        className={className}
        style={{ ...style, width: "35px", zIndex: "1" }}
        onClick={onClick}
      />
    );
  }

  const sliderSettings = {
    dots: true,
    prevArrow: <CustomArrow />,
    nextArrow: <CustomArrow />,
  };

  return (
    <Page>
      <Columns>
        <div>
          <NavLink
            to="/shop/"
            className={styles.back}
            onClick={() =>
              ReactGA.event({
                category: "CTA",
                action: "back_to_shop",
              })
            }
          >
            {"<"} Retour à la boutique
          </NavLink>
          <Title>{product.name}</Title>
          <div className={styles.description}>{product.description}</div>

          <Slider
            ref={sliderRef}
            {...sliderSettings}
            autoplay
            autoplaySpeed={5000}
            fade
          >
            {getProductImages(product.name, product.images[0]).map((img) => (
              <div className={styles.imgContainer}>
                <img src={img} alt="img" />
              </div>
            ))}
          </Slider>

          {isPreorder(product) && (
            <div className={styles.preorderWarning}>
              <img src={exclamationTriangle} alt="warning icon"></img>
              <div>
                {`Ce produit est en pré-commande et sera expédié à partir du ${formatDate(
                  product.metadata.release_date
                )}`}
              </div>
            </div>
          )}

          <ProductDetailPrices
            product={product}
            prices={prices}
            selectedPrice={selectedPrice}
            setSelectedPrice={setSelectedPrice}
            quantities={quantities}
            setQuantities={setQuantities}
            sliderRef={sliderRef}
            displayOnMobile
          />

          {product.metadata.detail && (
            <div className={styles.detail}>{product.metadata.detail}</div>
          )}
          {product.metadata.detail2 ? (
            <div className={styles.detail2}>{product.metadata.detail2}</div>
          ) : null}

          {product.metadata.context && (
            <OpenClose
              title="Contexte immersif"
              description={product.metadata.context}
              opened={true}
            />
          )}

          {product.metadata.ingredients && (
            <OpenClose
              title="Ingrédients"
              description={
                <div className={styles.ingredients}>
                  {product.metadata.ingredients
                    .split(".")
                    .map((i, index) => i && <div key={index}>{i}.</div>)}
                  {/* {product.metadata.ingredients} */}
                </div>
              }
            />
          )}

          {product.metadata.quantity && (
            <OpenClose
              title="Conseil de dégustation"
              description={
                <div className={styles.conseil}>
                  <div className={styles.info}>
                    <img src={quantity} alt="Quantité" />
                    <div>{product.metadata.quantity}</div>
                  </div>
                  <div className={styles.info}>
                    <img src={timer} alt="Temps d'infusion" />
                    <div>{product.metadata.time}</div>
                  </div>
                  <div className={styles.info}>
                    <img src={temperature} alt="Température" />
                    <div>{product.metadata.temperature}</div>
                  </div>
                </div>
              }
            />
          )}

          {product.metadata.description && (
            <OpenClose
              title="Description"
              description={
                <div>
                  <div>{product.metadata.description}</div>
                  <div className={styles.dimensions}>
                    <strong>Dimensions : </strong>
                    {product.metadata.dimensions}
                  </div>
                </div>
              }
              opened
            />
          )}
        </div>

        <div>
          <ProductDetailPrices
            product={product}
            prices={prices}
            selectedPrice={selectedPrice}
            setSelectedPrice={setSelectedPrice}
            quantities={quantities}
            setQuantities={setQuantities}
            sliderRef={sliderRef}
            displayOnMobile={false}
          />

          <PaymentInfos isATea={!product.metadata.description} />
        </div>
      </Columns>
    </Page>
  );
};
