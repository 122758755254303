export const formatPrice = (p: number) => {
  const divided = p / 100;

  const format = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  });

  const currency = format
    .formatToParts(1)
    .find((x) => x.type === "currency")?.value;

  return `${format.format(divided).slice(0, -2)} ${currency}`;
};

export const disablePayment = true;

export const isProd = window.location.hostname !== "localhost";

export const GA_MESUREMENT_ID = "G-CEK8LVCXJE";

export const isMobile = (): boolean => {
  return window.innerWidth <= 1024;
};
