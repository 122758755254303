import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Slider from "react-slick";
import classNames from "classnames";

import { receiveCart, StoreContext } from "../../../../containers/StoreContext";
import { setCookie } from "../../../cart/utils";
import { Button, QuantitySelection } from "../../../common-ui";

import { Cart, Price, Product } from "../../../api-client/types";
import { formatPrice } from "../../../common-ui/utils";

import doypackGray from "../../../../assets/icons/doypack-gray.png";
import doypackPink from "../../../../assets/icons/doypack-pink.png";
import teaboxGray from "../../../../assets/icons/teabox-gray.png";
import teaboxPink from "../../../../assets/icons/teabox-pink.png";

import { isPreorder } from "../../utils";

import styles from "./styles.module.scss";

type Props = {
  product: Product;
  prices: Price[];
  selectedPrice: Price | null;
  setSelectedPrice: (p: Price) => void;
  quantities: { [k: string]: number };
  setQuantities: (s: { [k: string]: number }) => void;
  sliderRef: React.RefObject<Slider>;
  displayOnMobile?: boolean;
};

export const ProductDetailPrices = ({
  product,
  prices,
  selectedPrice,
  setSelectedPrice,
  quantities,
  setQuantities,
  sliderRef,
  displayOnMobile,
}: Props) => {
  const store = useContext(StoreContext);
  const navigate = useNavigate();

  const [addedPrices, setAddedPrices] = useState<string[]>([]);
  const [loadingPrice, setLoadingPrice] = useState<string | null>(null);

  if (!selectedPrice) return null;

  const setCart = (c: Cart) => {
    setCookie("cart", JSON.stringify(c));
    receiveCart(store.dispatch, c);
  };

  const addToCart = (price: Price) => {
    setLoadingPrice(price.id);

    setTimeout(() => {
      setLoadingPrice(null);

      setAddedPrices([...addedPrices, price.id]);

      const cart = [...store.cart];
      const cartItem = cart.find((item) => item.price === price.id);
      const quantity = quantities[price.id];

      if (cartItem) {
        const index = cart.indexOf(cartItem);
        if (index > -1) cart[index].quantity += quantity;
      } else {
        cart.push({ quantity, price: price.id });
      }
      setCart(cart);

      toast.success(`${product.name} ajouté au panier`, {
        onClick: () => navigate("/cart/"),
      });
    }, 500);
  };

  const QUANTITY_LEFT = Math.min(
    Number(selectedPrice.metadata.quantity_left || 100),
    Number(product.metadata.quantity_left || 100)
  );

  const getStockMessage = (left: number) => {
    if (isPreorder(product)) return "Pré-commande";
    if (left > 5) return "En stock";
    if (left > 0) return `Plus que ${left} en stock`;
    return "Rupture de stock";
  };

  const getPriceIcon = (price: Price, index: number) => {
    if (index === 0) {
      if (price.id === selectedPrice?.id) return doypackPink;
      return doypackGray;
    } else {
      if (price.id === selectedPrice?.id) return teaboxPink;
      return teaboxGray;
    }
  };

  return (
    <div
      className={classNames(styles.checkout, {
        [styles.mobile]: displayOnMobile,
        [styles.desktop]: !displayOnMobile,
      })}
      key={selectedPrice.id}
    >
      {prices.length > 1 && (
        <>
          <div className={styles.title}>Choisir un format :</div>

          <div className={styles.priceList}>
            {prices
              .sort((a, b) => a.unit_amount - b.unit_amount)
              .map((price, index) => (
                <div
                  className={classNames(styles.format, {
                    [styles.selectedFormat]: price.id === selectedPrice?.id,
                  })}
                  onClick={() => {
                    setSelectedPrice(price);
                    if (sliderRef.current) sliderRef.current.slickGoTo(index);
                  }}
                >
                  <img src={getPriceIcon(price, index)} alt="format" />
                  <div className={styles.priceName}>{price.nickname}</div>
                </div>
              ))}
          </div>
        </>
      )}

      {/* Specific for Infusion du Mage Brun */}
      {prices.length === 1 && product.metadata.category === "Thés" && (
        <>
          <div className={styles.title}>Format :</div>
          <div className={styles.priceList}>
            <div className={classNames(styles.format, styles.selectedFormat)}>
              <img src={teaboxPink} alt="format" />
              <div className={styles.priceName}>{prices[0].nickname}</div>
            </div>
          </div>
        </>
      )}

      <div
        className={classNames(styles.price, {
          [styles.priceDiscount]: selectedPrice.metadata.standard_price,
        })}
      >
        {selectedPrice.metadata.standard_price ? (
          <div className={styles.discount}>
            {selectedPrice.metadata.standard_price}
          </div>
        ) : null}

        {formatPrice(selectedPrice.unit_amount)}
      </div>

      <div
        className={classNames(styles.stock, {
          [styles.soldLimited]: QUANTITY_LEFT <= 5,
          [styles.soldOut]: QUANTITY_LEFT < 1,
        })}
      >
        {getStockMessage(QUANTITY_LEFT)}
      </div>

      {QUANTITY_LEFT > 0 && (
        <>
          <QuantitySelection
            value={quantities[selectedPrice.id]}
            setValue={(q) =>
              setQuantities({ ...quantities, [selectedPrice.id]: q })
            }
            max={QUANTITY_LEFT}
          />
          {addedPrices.includes(selectedPrice.id) ? (
            <Button to="/cart/">{"Voir mon panier"}</Button>
          ) : (
            <Button
              onClick={() => addToCart(selectedPrice)}
              loading={loadingPrice === selectedPrice.id}
            >
              {"Ajouter au panier"}
            </Button>
          )}
        </>
      )}
    </div>
  );
};
