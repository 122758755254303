import { useContext, useEffect, useState } from "react";
import classNames from "classnames";

import { StoreContext } from "../../../containers/StoreContext";
import { apiClient } from "../../api-client/apiClient";
import { Order, OrderDetail, Price, Product } from "../../api-client/types";
import { CartItem } from "../../cart/CartItem";

import { FormLoader, Loader, Page, Title } from "../../common-ui";
import { formatPrice, isMobile } from "../../common-ui/utils";
import { formatDate } from "../../shop/utils";

import styles from "./styles.module.scss";

export const Orders = () => {
  const store = useContext(StoreContext);

  const [products, setProducts] = useState<Product[]>([]);
  const [prices, setPrices] = useState<Price[]>([]);

  const [orders, setOrders] = useState<Order[]>([]);
  const [ordersDetails, setOrdersDetails] = useState<{
    [id: string]: OrderDetail;
  }>({});
  const [openedOrders, setOpenedOrders] = useState<{
    [id: string]: boolean;
  }>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (store.user) {
      const p1 = apiClient.products.list().then((r) => {
        setProducts(r);
      });
      const p2 = apiClient.prices.list().then((r) => {
        setPrices(r);
      });
      const p3 = apiClient.orders
        .list({ email: store.user.email || "" })
        .then((response) => {
          setOrders(response.filter((p: Order) => p.status === "succeeded"));
        });

      Promise.all([p1, p2, p3]).then(() => setLoading(false));
    } else setLoading(false);
  }, [store.user]);

  const getOrder = (id: string) => {
    apiClient.orders
      .get({ orderId: id })
      .then((response) =>
        setOrdersDetails({ ...ordersDetails, [id]: response })
      );
  };

  if (loading)
    return (
      <Page>
        <Title>Historique des commandes</Title>
        <Loader />
      </Page>
    );

  return (
    <Page>
      <Title>Historique des commandes</Title>

      {!orders.length ? (
        <div className={styles.empty}>
          Vous n'avez aucune commande pour le moment.
        </div>
      ) : (
        orders.map((order, index) => (
          <div className={styles.order}>
            <div
              className={styles.payment}
              onClick={() => {
                if (!ordersDetails[order.id]) getOrder(order.id);
                setOpenedOrders({
                  ...openedOrders,
                  [order.id]: !openedOrders[order.id],
                });
              }}
            >
              <div>
                {!isMobile() && "Commande"} #{orders.length - index}
              </div>
              <div>{formatDate(order.created * 1000)}</div>
              <div>{formatPrice(order.amount)}</div>
              <div
                className={classNames(styles.chevron, {
                  [styles.chevronReverse]: openedOrders[order.id],
                })}
              ></div>
            </div>
            {openedOrders[order.id] ? (
              <div className={styles.itemList}>
                {ordersDetails[order.id] ? (
                  ordersDetails[order.id].map((item) => (
                    <CartItem
                      item={{
                        price: item.price.id,
                        quantity: Number(item.quantity),
                      }}
                      prices={prices}
                      products={products}
                    />
                  ))
                ) : (
                  <div className={styles.loader}>
                    <FormLoader />
                  </div>
                )}
              </div>
            ) : null}
          </div>
        ))
      )}
    </Page>
  );
};
