import { ReactNode } from "react";

import { Title } from "../Title";

import styles from "./styles.module.scss";

type Props = {
  title: string;
  image: string;
  content: ReactNode;
  imageClassName?: string;
};

export const Section = ({ title, image, content, imageClassName }: Props) => {
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <Title>{title}</Title>
        {content}
      </div>
      <div>
        <img
          src={image}
          alt="cosyfantasy"
          className={imageClassName || styles.image}
        />
      </div>
    </section>
  );
};
