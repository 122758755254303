import { Page, Title } from "../common-ui";
import stripe from "../../assets/images/logo-stripe.png";

import styles from "./styles.module.scss";

export const Stripe = () => {
  return (
    <Page className={styles.page}>
      <Title>Stripe</Title>

      <div>
        Stripe est notre prestataire de paiements en ligne, il nous permet de
        gérer vos transactions bancaires de façon sécurisée.
      </div>

      <div>
        Pour en savoir plus, rendez-vous sur{" "}
        <a href="https://stripe.com/" target="_blank" rel="noreferrer">
          le site de Stripe
        </a>
        .
      </div>

      <img src={stripe} alt="stripe" className={styles.stripe} />
    </Page>
  );
};
