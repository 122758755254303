import classnames from "classnames";

import styles from "./styles.module.scss";

export type SelectInputOption = { value: string; label: string };

export type SelectInputProps = {
  className?: string;
  disabled?: boolean;
  onChange: (s: SelectInputOption) => void;
  options: SelectInputOption[];
  value: string;
};
export const SelectInput = ({
  className,
  disabled,
  onChange,
  options,
  value,
}: SelectInputProps) => {
  return (
    <select
      className={classnames(styles.selectInput, className, {
        [styles.disabled]: disabled,
      })}
      onChange={(evt) => {
        const option = options.find((o) => evt.target.value === o.value);
        if (option) onChange(option);
      }}
      value={value}
    >
      {options.map((option) => (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};
