import { useContext, useEffect } from "react";
import { receiveCart, StoreContext } from "../../../containers/StoreContext";
import { Button, Page, Title } from "../../common-ui";
import { setCookie } from "../utils";

import styles from "./styles.module.scss";

export const PaymentSuccess = () => {
  const store = useContext(StoreContext);

  useEffect(() => {
    setCookie("cart", JSON.stringify([]));
    receiveCart(store.dispatch, []);
  }, [store.dispatch]);

  return (
    <Page className={styles.container}>
      <Title>Merci pour votre commande</Title>

      <div>Vous allez recevoir votre reçu par email.</div>
      <Button to="/shop/">Retour à la boutique</Button>
    </Page>
  );
};
