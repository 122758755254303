import { useNavigate } from "react-router";

import { Button } from "../../common-ui";

import styles from "./styles.module.scss";

export const WelcomeBanner = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.banner}>
      <div>Vous n'avez toujours pas commandé ?</div>
      <div>
        Bénéficiez de 10% de réduction sur votre première commande grâce au code{" "}
        <b>WELCOME10</b> !
      </div>
      <Button onClick={() => navigate("/shop/")} secondary>
        J'en profite maintenant !
      </Button>
    </div>
  );
};
