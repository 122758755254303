import { OpenClose, Page, Title } from "../common-ui";
import { mail } from "../legal/utils";

import logoLabels from "../../assets/images/labels.jpg";

import styles from "./styles.module.scss";

export const Faq = () => {
  return (
    <Page>
      <Title>FAQ</Title>

      <OpenClose
        title="Quels sont les délais de livraison ?"
        description="Les délais de livraison en France métropolitaine (via Colissimo) sont de 3 à 5 jours ouvrés, et de 5 à 7 jours ouvrés pour les envois vers l’international."
      />

      <OpenClose
        title="Quels sont les frais de livraison ?"
        description="Les frais de livraison varient de 4.50 € (Mondial Relay) à 6 € (Colissimo) pour la France Métropolitaine, et sont de 14 € pour un envoi à l'étranger."
      />

      <OpenClose
        title="Comment suivre ma commande ?"
        description={
          <>
            <div style={{ marginBottom: "10px" }}>
              Après avoir réalisé votre commande, vous allez recevoir votre reçu
              par email. Elle sera alors traitée par mes soins dans les plus
              brefs délais. Après avoir été confiée à notre prestataire de
              livraison, vous la recevrez sous 5 à 7 jours ouvrés.
            </div>
            <div>
              {" "}
              Si vous souhaitez un suivi plus détaillé de votre commande vous
              pouvez me joindre par mail à l’adresse suivante :{" "}
              <a
                href={`mailto:${mail}`}
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                {mail}
              </a>
            </div>
          </>
        }
      />

      <OpenClose
        title="Comment sont emballées les commandes ?"
        description="Toutes les commandes sont emballées avec soin et de la façon la plus écologique et éthique possible : nous utilisons du papier et du carton en grande partie, et nous donnons une seconde vie aux cartons d’emballage ou à des calages en papier bulles que nous recevons et réutilisons."
      />

      <OpenClose
        title="Comment sont gérés les paiements ?"
        description="Les paiements sont gérés par notre prestataire sécurisé Stripe. Il s'agit d'une solution spécialisée dans le paiement en ligne. Cela nous permet de gérer vos transactions bancaires en toute sécurité."
      />

      <OpenClose
        title="Où sont imprimés les produits Cosy Fantasy ?"
        description="Les produits de papeterie et les étiquettes des thés sont imprimés en France."
      />

      <OpenClose
        title="Je n'habite pas en France, je peux me faire livrer quand même ?"
        description={
          <>
            <div style={{ marginBottom: "10px" }}>
              Les livraisons sont actuellement possibles, en plus de la France
              métropolitaine, en Allemagne, Belgique, Danemark, Espagne,
              Finlande, Grèce, Irlande, Italie, Luxembourg, Portugal, Suède et
              Suisse.
            </div>
            <div>
              À noter que pour certains pays (la Suisse par exemple), vous êtes
              susceptibles d’avoir des frais de douane à régler à réception du
              colis, ce qui n’est pas du ressort ni de la responsabilité de Cosy
              Fantasy.
            </div>
          </>
        }
      />

      <OpenClose
        title=" Les thés Cosy Fantasy sont-ils bio ?"
        description={
          <>
            <div>
              Tous nos mélanges de thés possèdent le label{" "}
              <a
                href="https://www.ecocert.com/fr/article/5090889"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                {"Eurofeuille"}
              </a>{" "}
              <em>(agriculture biologique européenne)</em> ainsi que le label
              biologique allemand{" "}
              <a
                href="https://www.oekolandbau.de/en/"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                Bio Nach
              </a>
              {"."}
            </div>
            <img
              src={logoLabels}
              alt="logos of labels"
              className={styles.logoLabels}
            />
          </>
        }
      />
    </Page>
  );
};
