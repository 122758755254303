import ReactGA from "react-ga4";

import { Button, Page, Title } from "../common-ui";

import styles from "./styles.module.scss";

export const Contact = () => {
  return (
    <Page className={styles.container}>
      <Title>Contact</Title>

      <div>
        Vous avez une question ? Referez-vous à la FAQ pour voir les questions
        les plus fréquentes.
      </div>
      <Button
        to="/faq/"
        onClick={() =>
          ReactGA.event({
            category: "CTA",
            action: "go_to_faq",
          })
        }
      >
        Consulter la FAQ
      </Button>

      <div>
        Vous n'avez pas trouvé votre réponse ? N'hésitez pas à me contacter à
        l'adresse :
      </div>
      <div className={styles.mail}>contact@cosyfantasy.com</div>
    </Page>
  );
};
