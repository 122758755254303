import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile,
} from "firebase/auth";

import { Button, Page, TextInput, Title } from "../../common-ui";

import { receiveUser, StoreContext } from "../../../containers/StoreContext";
import { getSignInError } from "../utils";

import iconGoogle from "../../../assets/icons/google.svg";

import styles from "./styles.module.scss";

const provider = new GoogleAuthProvider();

export const Register = () => {
  const store = useContext(StoreContext);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");

  const [error, setError] = useState("");

  const [loading, setLoading] = useState<string | null>(null);

  useEffect(() => {
    if (store.user?.displayName) setDisplayName(store.user.displayName);
  }, [store.user]);

  if (!store.auth) return null;

  const resetForm = () => {
    setEmail("");
    setPassword("");
  };

  const createUser = () => {
    setLoading("create");
    createUserWithEmailAndPassword(store.auth!, email, password)
      .then((userCredential) => {
        updateProfile(userCredential.user, { displayName })
          .then(() => {
            setLoading(null);
            toast.dismiss();
            const user = userCredential.user;
            receiveUser(store.dispatch, {
              ...user,
              displayName,
            });
            toast.success("Modifications enregistrées !");
            navigate("/account/informations/");
          })
          .catch(() => {
            setLoading(null);
            navigate("/account/informations/");
          });
      })
      .catch((e) => {
        setError(getSignInError(e.code));
        setLoading(null);
        toast.error("Une erreur est survenue.");
      });
  };

  const signInWithGoogle = () => {
    setLoading("signInWithGoogle");
    signInWithPopup(store.auth!, provider)
      .then((userCredential) => {
        setLoading(null);
        toast.dismiss();
        const user = userCredential.user;
        receiveUser(store.dispatch, user);
        resetForm();
        navigate("/account/informations/");
      })
      .catch((e) => {
        setError(getSignInError(e.code));
        setLoading(null);
        toast.error("Une erreur est survenue.");
      });
  };

  if (store.user) navigate("/account/informations/");

  return (
    <Page>
      <Title>Créer mon compte</Title>

      <div className={styles.form}>
        <TextInput
          value={displayName}
          onChange={(e) => setDisplayName(e)}
          placeholder="Votre nom"
          margin="bottom"
          maxWidth
        />
        <TextInput
          value={email}
          onChange={(e) => setEmail(e)}
          placeholder="Votre email"
          margin="bottom"
          maxWidth
        />
        <TextInput
          value={password}
          onChange={(e) => setPassword(e)}
          placeholder="Votre mot de passe"
          margin="bottom"
          type="password"
          maxWidth
        />
        <div className={styles.error}>{error}</div>
      </div>

      <div className={styles.buttons}>
        <Button onClick={createUser} loading={loading === "create"}>
          Créer mon compte
        </Button>

        <Button
          onClick={signInWithGoogle}
          loading={loading === "signInWithGoogle"}
          className={styles.googleCta}
        >
          <img src={iconGoogle} alt="iconGoogle" />
          Se connecter avec Google
        </Button>

        <Button to="/login/" link>
          Se connecter
        </Button>
      </div>
    </Page>
  );
};
