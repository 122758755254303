import { useEffect } from "react";
import { toast } from "react-toastify";

import { Button } from "../../common-ui";
import closeIcon from "../../../assets/icons/close.svg";

import styles from "./styles.module.scss";
import { isMobile } from "../../common-ui/utils";

type Props = {
  isClosed: boolean;
  setIsClosed: React.Dispatch<React.SetStateAction<boolean>>;
};

export const WelcomePromoToast = ({ isClosed, setIsClosed }: Props) => {
  useEffect(() => {
    const custom = {
      paddingBottom: "15px",
      backgroundColor: "#acc1ae",
      fontSize: "22px",
      color: "#393939",
      width: isMobile() ? "90%" : "500px",
      boxShadow: "2x 2px 10px gray",
      cursor: "default",
      margin: "auto",
      marginBottom: isMobile() ? "20px" : "0",
    };
    const closeButton = (
      <img
        src={closeIcon}
        alt="close-icon"
        className={styles.closeButton}
        role="button"
        onClick={() => {
          setIsClosed(true);
          toast.dismiss();
        }}
      ></img>
    );

    if (!isClosed)
      toast.success(
        <div>
          Créez votre compte pour bénéficier de 10% sur votre première commande
          !
          <Button
            className={styles.button}
            to="/register/"
            secondary
            onClick={() => toast.dismiss()}
          >
            S'inscrire maintenant
          </Button>
        </div>,
        {
          position: "bottom-left",
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          style: custom,
          closeButton: closeButton,
        }
      );
  }, [isClosed, setIsClosed]);

  return <div />;
};
